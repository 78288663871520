@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-sans;
  }
}

.markdown * {
  all: revert;
}

body {
  font-size: clamp(1rem, 0.955rem + 0.23vw, 1.125rem);
}
